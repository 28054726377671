
import { usePrintQueueMaintenanceVM } from "presentation/hook/PrintQueue/usePrintQueueMaintenanceVM";
import { memo, useCallback, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import PrintQueueTablePanel from "./PrintQueueTablePanel";

const PrintQueueMaintenance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const printQueueVM = usePrintQueueMaintenanceVM();
    const [initScreen, setInitScreen] = useState<boolean>(false);

    const initialScreen = useCallback(async () => {
        setIsLoading(true);
        setInitScreen(true);
        try {
            const results = await Promise.allSettled([
                printQueueVM.loadDropdownOption(),
            ]);
            results.forEach((result, index) => {
                if (index === 0 && result.status === 'fulfilled') {

                }
            })
        } catch (error) {
            setIsLoading(false);
        }
    }, [printQueueVM]);

    useEffect(() => {
        !initScreen && initialScreen().then(async () => {
            printQueueVM.onSearch().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
        });

    }, [initScreen, initialScreen, printQueueVM])



    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
            <SliderPanel
                isOpen={false}
                draggable={false}
                leftSectionWidth={"0%"}
                rightSectionWidth={"100%"}
                leftChildren={<PrintQueueTablePanel />}
            />
        </div>
    </>
}

export default memo(PrintQueueMaintenance);